body {
    margin: 0;
    overflow: hidden;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
      'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
      sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
  
code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.cesium-viewer-measureContainer {
    position: relative;
    display: inline-block;
    vertical-align: middle;
}
.cesium-viewer-compass {
    position: absolute;
    top: 70px;
    right: 5px;
    display: none;
    text-align: center;
}
.compass-heading {
    font-size: 16px;
    color: white;
    filter:
            drop-shadow(-1px -1px 0px #000000)
            drop-shadow(1px -1px 0px #000000)
            drop-shadow(1px 1px 0px #000000)
            drop-shadow(-1px 1px 0px #000000);
}
.cesium-viewer-compassButton {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    margin: auto;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    background-color: transparent;
    border: none;
    cursor: pointer;
    z-index: 2;
}

.cesium-viewer-compassButtonTooltip {
    visibility: hidden;
    width: 95px;
    background-color: #555;
    color: #fff;
    text-align: center;
    padding: 5px 0;
    border-radius: 6px;


    /* Position the tooltip text */
    position: absolute;
    z-index: 1;
    top: 115%;
    left: 92%;
    margin-left: -60px;

    /* Fade in tooltip */
    opacity: 0;
    transition: opacity 0.3s;
}

/* Tooltip arrow */
.cesium-viewer-compassButtonTooltip::after {
    content: " ";
    position: absolute;
    bottom: 100%;  /* At the top of the tooltip */
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: transparent transparent #555 transparent;
}

/* Show the tooltip text when you mouse over the tooltip container */
.cesium-viewer-compassButton:hover .cesium-viewer-compassButtonTooltip {
    visibility: visible;
    opacity: 1;
}

.compass-svg {
    width: 120px;
    height: 120px;
    filter:
            drop-shadow(-1px -1px 0px #000000)
            drop-shadow(1px -1px 0px #000000)
            drop-shadow(1px 1px 0px #000000)
            drop-shadow(-1px 1px 0px #000000);
}

.cesium-viewer-toolbar {
    top: 20px !important;
    right: 20px !important;
}

.transform-editor-menu {
    position: absolute;
    stroke: none;
    fill: #edffff;
}

.transform-editor-button {
    padding: 0 !important;
    width: 25px;
    height: 25px;
    border-radius: 13px !important;
}

.transform-editor-button>svg {
    margin-top: 2px;
    margin-left: 2px;
}

.transform-editor-options {
    position: relative;
    left: -16px;
    background-color: #303336;
    color: #edffff;
}

.transform-editor-button-row>div {
    display: inline-block;
    cursor: pointer;
    padding: 4px;
    width: 34px;
    height: 34px;
    border: 1px solid #444;
}

.transform-editor-button-row>div.selected {
    background-color: #1e90ff;
}

.transform-editor-button-row>div:hover {
    background-color: #48b;
}

.cesium-measure-toolbar {
    width: 40px;
    height: 36px;
    overflow: hidden;
    transition: width 0.8s;
    white-space: nowrap;
    background: transparent;
    fill: transparent;
    display: flex;
    align-items: center;
}

.cesium-measure-toolbar.expanded {
    width: 440px;
}

.cesium-measure-button {
    stroke-width: 2;
    background-color: #243852;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    position: relative;
    box-sizing: border-box;
    outline: 0;
    cursor: pointer;
    fill: transparent;
    padding: 4px 8px;
    height: 36px;
    width: 36px;
    border-radius: 8px;
    transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    border: 1px solid rgba(36, 56, 82, 0.5);
    color: white;
    stroke: white;
    box-shadow: 0 1px 2px 0 rgb(0 0 0 / 0.05);
    margin: 0 4px;
}

.cesium-measure-button:hover {
    background-color: white;
    border: 1px solid white;
    color: #243852;
    stroke: #243852;
}

.cesium-measure-button:last-of-type {
    border-right: none;
}

.cesium-measure-button.active {
    background-color: white;
    border: 1px solid white;
    color: #243852;
    stroke: #243852;
}

.cesium-measure-button-main {
    stroke-width: 1.3;
}

.cesium-measure-help {
    stroke-width: 0;
    padding: 6px 8px;
    fill: white;
    transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    fill 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.cesium-measure-help:hover {
    background-color: white;
    fill: #243852;
}

.cesium-measure-help.active {
    stroke-width: 0;
    padding: 6px 8px;
    fill: #243852;
}

.cesium-measure-instructions {
    position: absolute;
    max-height: 500px;
    border: 1px solid #444;
    overflow-y: auto;
    border-radius: 0;
    background-color: white;
    color: #243852;
    padding: 10px;
    stroke: #243852;
    stroke-width: 2;
}

.cesium-measure-icon {
    margin-right: 10px;
}

.cesium-measure-instructions .bold {
    margin-bottom: 3px;
}

.cesium-measure-instructions>ul {
    padding-left: 20px;
}

.cesium-measure-data-management-widget {
    background-color: white;
    box-shadow: 0 1px 2px 0 rgb(0 0 0 / 0.05);
    border-radius: 0.375rem;
    border-bottom-width: 4px;
    margin-bottom: 10px;
    padding: 16px;
}

.cesium-measure-data-management-widget>ul {
    border-top-width: 0px;
    border-bottom-width: 1px;
    border-color: rgb(229 231 235);
}

.cesium-measure-data-management-textarea {
    width: 18rem;
    box-shadow: 0 1px 2px 0 rgb(0 0 0 / 0.05);
    display: block;
    border-width: 1px;
    border-color: rgb(209 213 219);
    border-radius: 0.375rem;
    outline: none;
    padding: .5rem .75rem;
    resize: none;
    font-family: Public Sans, sans-serif;
}

.cesium-measure-data-management-textarea:focus {
    border-color: #20324a;
}


.cesium-measure-list-container ul {
    border-top-width: 0px;
    border-bottom-width: 1px;
    border-color: rgb(229 231 235);
    max-height: 65vh;
    overflow-y: auto;
}

.cesium-measure-list-container ul>li {
    background-color: white;
    box-shadow: 0 1px 2px 0 rgb(0 0 0 / 0.05);
    border-radius: 0.375rem;
    margin-bottom: 6px;
    padding: 16px;
}

.cesium-measure-list-container ul>li:last-of-type {
    border-top-width: 0px;
    border-bottom-width: 1px;
    padding-bottom: 4px;
    border-color: rgb(229 231 235);
}

.cesium-viewer-settingsContainer {
    position: relative;
    display: inline-block;
    vertical-align: middle;
}

.cesium-settings-button .cesium-svgPath-svg {
    padding: 6px;
    box-sizing: border-box;
}

#cesium-settings-widget {
    color: black;
    background-color: white;
    box-shadow: 0 1px 2px 0 rgb(0 0 0 / 0.05);
    border-radius: 0.375rem;
    border-bottom-width: 4px;
    position: absolute;
    top: 40px;
    right: 0;
    z-index: 10;
    max-height: 0;
    overflow: hidden;
}

#cesium-settings-widget > div {
    padding: 16px;
}

#cesium-settings-widget label {
    white-space: nowrap;
    cursor: pointer;
    user-select: none;
}

#cesium-settings-widget label > input {
    margin-right: 10px;
}

.cesium-viewer-isolateContainer {
    position: relative;
    display: inline-block;
    vertical-align: middle;
}

.cesium-isolate-button .cesium-svgPath-svg {
    padding: 6px;
    box-sizing: border-box;
}

.cesium-data-file-buttons {
    width: 100%;
    text-align: right;
}

.cesium-data-file-buttons>label {
    display: inline-flex;
    margin-left: 0.5rem;
    align-items: center;
    padding: 0.375rem 0.625rem;
    border-color: transparent;
    font-size: 0.75rem;
    /* 12px */
    line-height: 1rem;
    /* 16px */
    font-weight: 500;
    border-radius: 0.375rem;
    box-shadow: 0 1px 2px 0 rgb(0 0 0 / 0.05);
    background-color: #20324a;
    border: 1px solid #20324a;
    color: white;
}

.cesium-data-file-buttons>label:hover {
    background-color: #162231;
    border: 1px solid #162231;
    outline: none;
}

.cesium-data-file-buttons>label>input {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border-width: 0;
    cursor: pointer;
}

.cesium-data-file-button {
    display: inline-flex;
    margin-left: 0.5rem;
    align-items: center;
    padding: 0.375rem 0.625rem;
    border-color: transparent;
    font-size: 0.75rem;
    /* 12px */
    line-height: 1rem;
    /* 16px */
    font-weight: 500;
    border-radius: 0.375rem;
    box-shadow: 0 1px 2px 0 rgb(0 0 0 / 0.05);
    background-color: #20324a;
    color: white;
    cursor: pointer;
    border: 1px solid #243852;
}

.cesium-data-file-button:hover {
    background-color: #162231;
    border: 1px solid #162231;
    outline: none;
}

.cesium-measure-data-management-widget #measurement_info.hidden {
    display: none;
}

.cesium-measure-data-management-widget .measurement_item>div {
    display: flex;
    align-items: center;
    padding-left: 1rem;
    padding-right: 1rem;
}

.cesium-widget-credits {
    display: none !important;
}

.cesium-toolbar-button {
    background-color: #243852 !important;
    display: inline-flex !important;
    align-items: center !important;
    justify-content: center !important;
    position: relative !important;
    box-sizing: border-box !important;
    outline: 0 !important;
    cursor: pointer !important;
    height: 36px !important;
    width: 36px !important;
    border-radius: 8px !important;
    transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    fill 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms !important;
    border: 1px solid rgba(36, 56, 82, 0.5) !important;
    fill: white !important;
    box-shadow: 0 1px 2px 0 rgb(0 0 0 / 0.05) !important;
    margin: 0 4px !important;
}

.cesium-toolbar-button:last-of-type {
    margin-right: 0 !important;
}

.cesium-toolbar-button:hover, .cesium-toolbar-button.active {
    background: white !important;
    border: 1px solid white !important;
    color: #243852 !important;
    fill: #243852 !important;
    box-shadow: 0 1px 2px 0 rgb(0 0 0 / 0.05) !important;
}

.clipping-planes-controls {
    position: fixed;
    left:0;
    right:0;
    top:20px;
    margin:auto;
    display:flex;
    justify-content: center;
    width:400px;
}

.clipping-planes-controls button {
    padding: 10px;
    cursor: pointer;
    margin: 0 10px;
}
